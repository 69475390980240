import { useWindowWidth } from '@react-hook/window-size';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJs,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  Tooltip as ChartTooltip,
  Title,
} from 'chart.js';
import { useState } from 'react';

ChartJs.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartTooltip,
  Legend
);

const BarChart = dynamic(
  () => import('react-chartjs-2').then((mod) => mod.Bar),
  {
    ssr: false,
  }
);

export function OfferChart({ dataSets, monthClick }) {
  const width = useWindowWidth();
  const userStore = useSelector((state) => state.user.value);
  const [selectedBar, setSelectedBar] = useState(null);

  return (
    <BarChart
      options={{
        indexAxis: width <= 1024 ? 'y' : 'x',
        maintainAspectRatio: false,
        responsive: true,
        elements: {
          bar: {
            borderRadius: 3,
            borderColor: 'rgba(212, 169, 118, 1)',
            // borderWidth: {
            //   top:  width >= 1024 ? 10 : 0,
            //   right: width <= 1024 ? 10 : 0
            // },

            borderWidth: (context) => {
              const isUpdatePrice = context.datasetIndex === 1;
              if (isUpdatePrice) {
                return 0;
              }
              return {
                top: width >= 1024 ? 10 : 0,
                right: width <= 1024 ? 10 : 0,
              };
            },
            hoverBackgroundColor: '#BB9269',
            hoverBorderColor: '#BB9269',
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            anchor: 'end',
            offset: 10,
            align: 'start',
            textAlign: 'center',
            color: 'rgba(255, 255, 255, .9)',
            font: {
              size: 13,
              weight: 'bold',
            },
            formatter: (value, context) => {
              if (!value) return '';
              const isUpdatePrice = context.datasetIndex === 1;

              if (isUpdatePrice) {
                return '\u21bb';
              }
              const roundedValue = Math.round(value);
              const price = new Intl.NumberFormat('en-GB', {
                maximumFractionDigits: 0,
              }).format(roundedValue);

              if (width <= 1024) {
                return `${price}`;
              }

              return `${userStore.currency} \n ${price}`;
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const isUpdatePrice = context.datasetIndex === 1;
                let label = context.dataset.label || '';

                if (isUpdatePrice) {
                  label = 'Sorry there is currently no offer for this month';
                  return label;
                }

                if (label) {
                  label += ': ';
                }

                if (width > 1024) {
                  if (context.parsed.y !== null) {
                    label += new Intl.NumberFormat('en-GB', {
                      style: 'currency',
                      currency: userStore?.currency,
                    }).format(context.parsed.y);
                  }
                }

                if (width <= 1024) {
                  if (context.parsed.x !== null) {
                    label += new Intl.NumberFormat('en-GB', {
                      style: 'currency',
                      currency: userStore?.currency,
                    }).format(context.parsed.x);
                  }
                }

                return label;
              },
              labelColor: function () {
                return {
                  borderColor: 'transparent',
                  backgroundColor: 'transparent',
                };
              },
            },
          },
        },
        interaction: {
          mode: 'point',
        },
        onClick: function (event, elements) {
          if (elements.length > 0) {
            const index = elements[0].index;
            if (index === selectedBar) {
              setSelectedBar(null);
              monthClick(null);
            } else {
              setSelectedBar(index);
              monthClick(elements);
            }
          }
        },

        onHover: (event, elements) => {
          if (elements.length > 0) {
            event.native.target.style.cursor = 'pointer';
          } else {
            event.native.target.style.cursor = 'default';
          }
        },
      }}
      plugins={[ChartDataLabels]}
      data={{
        ...dataSets,
        datasets: dataSets.datasets.map((dataset) => ({
          ...dataset,
          backgroundColor: dataset.data.map((_, index) =>
            index === selectedBar ? '#3C6562' : 'rgba(212, 169, 118, 0.8)'
          ),

          borderColor: dataset.data.map((_, index) =>
            index === selectedBar ? '#00454A' : 'rgba(212, 169, 118, 1)'
          )
        })),
      }}
    />
  );
}
